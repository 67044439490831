import project from '../data/projects.json'

export default function Project() {


  return (
    <section className="relative h-full w-full flex justify-center items-center">
     <div className="lg:h-3/4 lg:max-h-3/4 grid grid-cols-1 grid-flow-row lg:grid-rows-3 lg:grid-flow-col gap-4"> {/* Adjust gap as needed */}
      {project && project.map((p,index) => (
          <a
            key={index}
            className="relative lg:h-full h-[12rem] scale-100 hover:scale-105 transition-transform duration-700" 
            style={{ width: "20rem" }} // Adjust height as needed
            href={p.link}
            target="blank"
            rel="noreferrer" 
          >
            {/* {media.length > 0 && ( */}
            {/* // media.find(m => m.id === p.acf.thumbnail)?.source_url */}
                <img src={require(`../data/img/${p.thumbnail}`)} alt="Thumbnail"
                className="absolute h-full w-full object-cover" />
            {/* )} */}
            {/* Insert content here if needed */}
            <div className="relative w-full h-full flex justify-center items-center text-white
             bg-gradient-to-t from-black/90 to-transparent opacity-0 backdrop-blur-0 transition-all duration-500
             hover:opacity-100 hover:backdrop-blur-[1px]"
             >
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" viewBox="0 0 16 16">
                <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393"/>
              </svg>
              <span className="absolute bottom-5 left-0 text-lg font-sans px-5">
                {p.title}
              </span>
            </div>
          </a>
        ))}
      </div>
      {/* <iframe src="https://player.vimeo.com/video/859296620?h=200d647613" width="640" height="640" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe> */}

    </section>
  );
}
