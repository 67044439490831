import React from "react"

export default function About({description,specializations}) {


    return (
        <>
            <section className="h-full w-full flex justify-center items-center">
                <div className="w-3/4 lg:w-2/3">
                    <h1 className="font-header text-3xl lg:text-5xl lg:-ml-1 text-primary mb-4 lg:mb-8">
                        Info
                    </h1>
                    <p className="font-sans text-xs lg:text-sm mb-4 lg:mb-8">
                        {description}
                    </p>
                    <h1 className="font-header text-3xl lg:text-5xl lg:-ml-1 text-primary mb-4 lg:mb-8">
                        Skills
                    </h1>
                    <div className="flex">
                        {specializations && specializations.map((s) => (
                            <img className="w-6 lg:w-12 mr-3" src={require(`../data/svg/${s}`)} alt="adobe" />
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}