import React, { useState,useEffect,useRef } from "react"
import info from './data/descriptions.json'
import Landing from "./sections/landing"
import About from "./sections/about"
import Footer from "./sections/footer"
import Project from "./sections/projects"

import { gsap } from "gsap"
import { useGSAP } from "@gsap/react"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { ScrollToPlugin } from "gsap/all"

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger)


function App() {
  const containerRef = useRef(null)
  const [nav, setNav] = useState(false)
  const [scrollamount, setScroll] = useState()

  useEffect(() => {
    const handleWheel = (event) => {
      // Prevent the default horizontal scroll
      if (event.deltaX !== 0) {
        event.preventDefault();
      }

      // Rebind the x-axis scroll to the y-axis
      window.scrollBy(0, event.deltaX);
    };

    window.addEventListener('wheel', handleWheel, { passive: false });

    // Cleanup the event listener on component unmount
    return () => {
      // window.removeEventListener('wheel', handleWheel);
      window.addEventListener('wheel', handleWheel, { passive: false });

    };
  }, []);

  useGSAP(() => {

    const matchMedia = window.matchMedia("(max-width: 1024px)");

    if (matchMedia.matches) return

    let scrollContainer = containerRef.current;
    let sections = gsap.utils.toArray(".section");
    let maxWidth = 0;

    const getMaxWidth = () => {
      maxWidth = 0;
      sections.forEach((section) => {
        maxWidth += section.offsetWidth;
      });
    };
    
    getMaxWidth();
    ScrollTrigger.addEventListener("refreshInit", getMaxWidth);

    // GSAP create horizontal scroll
    let horizontalScroll = gsap.to(sections, {
      // x: `350vw`,
      x: () => `-${maxWidth - window.innerWidth}`,
      // ease: 'none',
      scrollTrigger: {
        trigger: scrollContainer,
        pin: true,
        scrub: 0.8,
        end: () => `+=${maxWidth - window.innerWidth}`,
        invalidateOnRefresh: true,
        // markers:true,
        onUpdate: (self) => {
          const progress = self.progress;
          const percentage = Math.round(progress * 100);
          setScroll(percentage);
          // console.log(gsap.getProperty(container, "x"))
        },
      }
    },[]);


    let getPosition = getScrollLookup(".section", {start: "start center", containerAnimation: horizontalScroll});

    gsap.utils.toArray(".navigation a").forEach(el => {
      el.addEventListener("click", e => {
        e.preventDefault();
        gsap.to(window, {
          scrollTo: getPosition(el.getAttribute("href")),
          overwrite: "auto",
          duration: 1,
          ease:"expo.inOut",
        });
      });
    });

    function getScrollLookup(targets, {start, pinnedContainer, containerAnimation}) {
      targets = gsap.utils.toArray(targets);
      let initted,
          triggers = targets.map((el, i) => ScrollTrigger.create({
            trigger: el,
            start: start,
            pinnedContainer: pinnedContainer,
            refreshPriority: -10,
            // markers:true,
            onRefresh(self) {
              if (initted && Math.abs(self.start) > 999999) {
                triggers[i].kill();
                triggers[i] = ScrollTrigger.create({
                  trigger: el,
                  start: start,
                  pinnedContainer: pinnedContainer,
                  refreshPriority: -10,
                });
              }
            },
            containerAnimation: containerAnimation
          })),
          st = containerAnimation && containerAnimation.scrollTrigger,
          lookups = [],
          lookup = target => {
            let t = gsap.utils.toArray(target)[0],
                i = targets.indexOf(t);
            if (i < 0) {
              for (i = 0; i < lookups.length; i++) {
                if (lookups[i].targets.indexOf(t) !== -1) {
                  return lookups[i](t);
                }
              }
              return console.warn("target not found", target);
            }
            return triggers[i].vars.containerAnimation ? st.start + (triggers[i].start / containerAnimation.duration()) * (st.end - st.start) : triggers[i].start;
          };
      lookup.targets = targets;
      lookup.add = (targets, config) => {
        lookups.push(getScrollLookup(targets, config));
        return lookup;
      };
      initted = true;
      return lookup;
    }

  }, []);


  useEffect(() => {
    const disclaim = document.getElementById("disclaimer")
    const load = document.getElementById("loadpage")
    const scrollnext = document.getElementById("scrollnext")

    // window.onload = () => {

      const tl = gsap.timeline()
      
      tl.to(disclaim,{
        opacity:0,
        duration:0.6
      },1)
      .to(load,{
        opacity:0,
        duration:1.5
      })

      const matchMedia = window.matchMedia("(max-width: 1024px)");

      if (matchMedia.matches){
        tl.fromTo(scrollnext,{
          y:-20,
          opacity:0
        },{
          y:0,
          opacity:1,
          duration:1
        },3)
      }else{
        tl.fromTo(scrollnext,{
          x:-50,
          opacity:0
        },{
          x:0,
          opacity:1,
          duration:1
        },3)
      }

      tl.play()

    // }
    return () => {
      
    };
  }, []);

  return (
    <div>
      <nav className="hidden fixed z-20 w-screen h-28 p-10 lg:flex justify-between items-center">
        <div className="relative cursor-pointer hover:scale-105 scale-100 duration-300 transition-all"
        onClick={() => {
          setNav((prevNav) => !prevNav); // Toggle the nav state
        }}>
            <svg className={`absolute ${(nav ? "animate-spin" : "")}`} style={{opacity : (nav ? "1" : "0")}} width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 39.375C16.1266 39.375 11.4529 37.4391 8.00691 33.9931C4.56093 30.5471 2.625 25.8734 2.625 21C2.625 16.1266 4.56093 11.4529 8.00691 8.00691C11.4529 4.56093 16.1266 2.625 21 2.625C25.8734 2.625 30.5471 4.56093 33.9931 8.00691C37.4391 11.4529 39.375 16.1266 39.375 21C39.375 25.8734 37.4391 30.5471 33.9931 33.9931C30.5471 37.4391 25.8734 39.375 21 39.375ZM21 42C26.5695 42 31.911 39.7875 35.8492 35.8492C39.7875 31.911 42 26.5695 42 21C42 15.4305 39.7875 10.089 35.8492 6.15076C31.911 2.21249 26.5695 0 21 0C15.4305 0 10.089 2.21249 6.15076 6.15076C2.21249 10.089 0 15.4305 0 21C0 26.5695 2.21249 31.911 6.15076 35.8492C10.089 39.7875 15.4305 42 21 42Z" fill="black"/>
            <path d="M12.1957 12.1957C12.3177 12.0735 12.4625 11.9765 12.622 11.9104C12.7814 11.8442 12.9524 11.8102 13.125 11.8102C13.2976 11.8102 13.4686 11.8442 13.628 11.9104C13.7875 11.9765 13.9323 12.0735 14.0542 12.1957L21 19.1441L27.9457 12.1957C28.0678 12.0737 28.2126 11.9769 28.3721 11.9109C28.5315 11.8448 28.7024 11.8108 28.875 11.8108C29.0476 11.8108 29.2185 11.8448 29.3779 11.9109C29.5373 11.9769 29.6822 12.0737 29.8042 12.1957C29.9263 12.3178 30.0231 12.4626 30.0891 12.6221C30.1552 12.7815 30.1892 12.9524 30.1892 13.125C30.1892 13.2976 30.1552 13.4685 30.0891 13.6279C30.0231 13.7873 29.9263 13.9322 29.8042 14.0542L22.8559 21L29.8042 27.9457C29.9263 28.0678 30.0231 28.2126 30.0891 28.3721C30.1552 28.5315 30.1892 28.7024 30.1892 28.875C30.1892 29.0476 30.1552 29.2185 30.0891 29.3779C30.0231 29.5373 29.9263 29.6822 29.8042 29.8042C29.6822 29.9263 29.5373 30.0231 29.3779 30.0891C29.2185 30.1552 29.0476 30.1892 28.875 30.1892C28.7024 30.1892 28.5315 30.1552 28.3721 30.0891C28.2126 30.0231 28.0678 29.9263 27.9457 29.8042L21 22.8559L14.0542 29.8042C13.9322 29.9263 13.7873 30.0231 13.6279 30.0891C13.4685 30.1552 13.2976 30.1892 13.125 30.1892C12.9524 30.1892 12.7815 30.1552 12.6221 30.0891C12.4626 30.0231 12.3178 29.9263 12.1957 29.8042C12.0737 29.6822 11.9769 29.5373 11.9109 29.3779C11.8448 29.2185 11.8108 29.0476 11.8108 28.875C11.8108 28.7024 11.8448 28.5315 11.9109 28.3721C11.9769 28.2126 12.0737 28.0678 12.1957 27.9457L19.1441 21L12.1957 14.0542C12.0735 13.9323 11.9765 13.7875 11.9104 13.628C11.8442 13.4686 11.8102 13.2976 11.8102 13.125C11.8102 12.9524 11.8442 12.7814 11.9104 12.622C11.9765 12.4625 12.0735 12.3177 12.1957 12.1957Z" fill="black"/>
            </svg>

            <svg className="relative" style={{opacity : (nav ? "0" : "1")}} width="44" height="36" viewBox="0 0 44 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.6875 33.5C0.6875 32.9861 0.891629 32.4933 1.25498 32.13C1.61833 31.7666 2.11114 31.5625 2.625 31.5625H41.375C41.8889 31.5625 42.3817 31.7666 42.745 32.13C43.1084 32.4933 43.3125 32.9861 43.3125 33.5C43.3125 34.0139 43.1084 34.5067 42.745 34.87C42.3817 35.2334 41.8889 35.4375 41.375 35.4375H2.625C2.11114 35.4375 1.61833 35.2334 1.25498 34.87C0.891629 34.5067 0.6875 34.0139 0.6875 33.5ZM0.6875 18C0.6875 17.4861 0.891629 16.9933 1.25498 16.63C1.61833 16.2666 2.11114 16.0625 2.625 16.0625H41.375C41.8889 16.0625 42.3817 16.2666 42.745 16.63C43.1084 16.9933 43.3125 17.4861 43.3125 18C43.3125 18.5139 43.1084 19.0067 42.745 19.37C42.3817 19.7334 41.8889 19.9375 41.375 19.9375H2.625C2.11114 19.9375 1.61833 19.7334 1.25498 19.37C0.891629 19.0067 0.6875 18.5139 0.6875 18ZM0.6875 2.5C0.6875 1.98614 0.891629 1.49333 1.25498 1.12998C1.61833 0.766629 2.11114 0.5625 2.625 0.5625H41.375C41.8889 0.5625 42.3817 0.766629 42.745 1.12998C43.1084 1.49333 43.3125 1.98614 43.3125 2.5C43.3125 3.01386 43.1084 3.50667 42.745 3.87002C42.3817 4.23337 41.8889 4.4375 41.375 4.4375H2.625C2.11114 4.4375 1.61833 4.23337 1.25498 3.87002C0.891629 3.50667 0.6875 3.01386 0.6875 2.5Z" fill="black"/>
            </svg>
        </div>
        <div className="navigation flex font-header text-5xl transition-transform duration-500 select-none" style={{transform : (nav ? "translate(0,0)" : "translate(0,-200%)")}}>
            <a className="mx-8 cursor-pointer"
            href="#home"
            >
                Home
            </a>
            <a className="mx-8 cursor-pointer"
            href="#info"
            >
                Info
            </a>
            <a className="mx-8 cursor-pointer"
            href="#works"
            >
                Works
            </a>
            <a className="mx-8 cursor-pointer"
            href="#contact"
            >
                Contact
            </a>
        </div>
      </nav>
      <div id="loadpage" className="pointer-events-none fixed z-[1000] p-12 flex justify-center items-center h-screen w-screen overflow-hidden bg-primary">
        <span id="disclaimer" className="text-xs text-secondary">
            Reproduction, reposting and modification of this site is prohibited.
        </span>
      </div>
      <div className={`lg:flex lg:w-[${250+100}vw] w-full lg:h-screen h-full bg-secondary lg:overflow-hidden`} ref={containerRef}>
        <div id="home" className="section relative h-screen lg:h-full w-full lg:w-[100vw] lg:min-w-[100vw]">
          <div id="scrollnext" className="pointer-events-none absolute bottom-20 lg:bottom-0 lg:right-20 lg:h-full w-full lg:w-auto " >
            <div className="transition-all lg:h-full w-full lg:w-auto rotate-90 lg:rotate-0 flex justify-center lg:items-center" style={{opacity:`${scrollamount>2 ? "0":"1"}`}}>
              <span className="text-xs -rotate-90 translate-x-10">
                Scroll Down
              </span>
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671"/>
              </svg>
            </div>
          </div>
          <Landing />
        </div>
        <div id="info" className="section h-full pr-[10vw] w-full lg:min-w-[70vw] mb-48 lg:mb-0">
          <About
            description={info.description}
            specializations={info.apps}
          />
        </div>
        <div id="works" className={`section h-full w-full lg:min-w-[${100}vw]`}><Project /></div>
        <div id="contact" className="section h-[30rem] lg:h-full w-full lg:min-w-[100vw]">
          <Footer
            bh={info.behance}
            vm={info.vimeo}
            ig={info.instagram}
          />
        </div>
      </div>
      <div className="fixed bottom-8 left-1/2 transform -translate-x-1/2">
        <div className="w-48 h-1 rounded-full border-primary border-[1px] transition-all duration-700" style={{transform:`${scrollamount>2 ? "translateY(0)":"translateY(20px)"}`, opacity:`${scrollamount>2 ? "1":"0"}`}}>
          <div className="h-full bg-primary" style={{width:`${scrollamount}%`}} />
        </div>
      </div>
      
    </div>
  )
}

  // return (
  //   <div className="container bg-secondary h-screen w-full">
  //     <div className="pagesection h-full">
  //       <Landing />
  //     </div>
  //     <div className="pagesection h-full">
  //       <About />
  //     </div>

      
  //   </div>
  // )
// }

export default App

