export default function Landing() {


    return (
        <>
            <section className="max-w-[100vh] h-full w-full flex justify-center items-center">
                <div>
                    <div className="h-32"/>
                    <h1 className="font-header text-6xl lg:text-[8rem] text-primary mb-3 lg:mb-6">
                        Colin Tan
                    </h1>
                    <div className="h-32 flex">
                        <span className="lg:pl-2 font-sans font-extralight antialiased text-base lg:text-xl">
                            Digital Portfolio
                        </span>
                    </div>
                </div>
            </section>
        </>
    )
}